export const translations = {
    en: {
        about: {
            learnMore: 'Learn more about RealColibri',
            autotrading: {
                header: 'Autotrading',
                welcome:
                    'Welcome to the world of high-tech trading with RealColibri!',
                description: `RealColibri is an advanced automated trading system specializing in futures
                            contracts of crypto exchanges and global markets. Our system is characterized
                            by outstanding long-term success and an innovative approach to investing.`,
                unique: 'What makes the RealColibri system unique:',
                list: [
                    'Excellent results: RealColibri delivers impressive results over the long term. Our system is based on advanced algorithms and rigorous data analysis, which allows us to achieve stable and significant investment growth, as well as minimize risk.',
                    'Automated approach: Forget about the need to constantly monitor the market! RealColibri automatically analyzes thousands of data points and makes real-time trading decisions, freeing you from stress and increasing your investment performance.',
                    'Diversification: The ability to trade on different instruments within one account by creating additional API key or login-password pairs and using them on multiple RealColibri accounts.',
                    `Use of RCC token: RealColibri uses RCC as a commission for services in the amount of 50% of the deposit size. The commission deposit is spent on
                    each trade. This innovative solution allows investors to "fuel" the algorithm for trading, ensuring it is always running and optimizing the investment
                    process. Having spent 1 RCC on commission, the investor receives 2 times more on their trading account! At the same time, the investor keeps the profit in its
                    entirety. And thanks to the constant growth of RCC value, RealColibri's commission becomes smaller and smaller over time.`,
                    'Free trial period: Try our algorithm for free for one week! You can also extend this period for another week for each customer you invite to our system. Read more about this feature in the FAQ section.',
                ],
            },
            realColibriCoin: {
                header: 'RealColibri Coin',
                welcome: 'Open new horizons with RealColibri Coin!',
                description: `RCC is more than just a cryptocurrency. It is a key element of our
                            entire ecosystem, contributing to its constant growth and development.
                            Think of RCC as the fuel that powers our services and makes them even
                            more powerful and efficient. The constant turnover of RCC in the RealColibri
                            ecosystem contributes to the token's value and creates a favorable environment
                            for all participants:`,
                list: [
                    `Favorable commission: The higher the value of RCC, the fewer tokens will be
                    charged for RealColibri's trading automation services, making them even more
                    affordable and attractive to traders.`,
                    `Token Exchange: Our ecosystem also allows users to sell/exchange RCC with each
                    other without RealColibri's involvement. In the future, when we list RCC on
                    centralized and decentralized exchanges, the token will be tradable. This gives 
                    you even more freedom and flexibility in managing your assets.`,
                ],
                whatCanIDo: 'What can I do with RCC?',
                actions: [
                    'Fund the commission deposit and use them as a commission for using autotrading.',
                    'Sell directly to another RealColibri member who wishes to purchase RCC.',
                    'Trade them on exchanges once RCC is listed in the future.',
                ],
                joinNow:
                    'Join RealColibri today and discover new opportunities and paths to financial success!',
            },
            faq: {
                header: 'FAQ',
                questions: [
                    {
                        question: 'How do I start trading?',
                        answer: `To start automated trading you will need: <br/>
                            Register on our website and connect TronLink wallet. <br/>
                            Purchase RCC and refill the commission deposit in your RealColibri account. <br/>
                            Connect your exchange trading account on the trading page using API keys,
                            select a trading instrument and start the algorithm.`,
                    },
                    {
                        question:
                            'How much can I start trading with and what is the return?',
                        answer: `The minimum deposit is 10,000 USDT. The optimal deposit size should be at least
                            3.75 times the minimum deposit. If the deposit size is lower than the optimal
                            one, the monthly profit depends on the minimum deposit size. If the deposit
                            size is higher than the optimal one, the calculation of compound interest is
                            enabled, and the monthly profit depends on the current deposit size and will
                            increase proportionally to the deposit size. Every 5% of profit increases the
                            position volume by 5%. Therefore, the larger the deposit, the higher the return.`,
                    },
                    {
                        question: 'Can I try autotrading for free?',
                        answer: `Yes, we offer the ability to use the algorithm for free for one week.
                        During this period you will not need to have RCC to run the algorithm.
                        To activate the free trial period, send an email to mail@realcolibri.com
                        with the appropriate request and the address of your Tronlink wallet with which you registered on our website,
                        and we will grant you access as soon as possible.
                        Each active customer you invite to our system is guaranteed to add one additional week of free period.
                        Simply invite a friend, provide the address of their TronLink wallet registered in our system, and you get one more week of free trial.
                        Each customer must be registered in the RealColibri system via TronLink (Full Access) to receive a free trading trial. 
                        Inscription et connexion à RealColibri via TronLink`,
                    },
                    {
                        question: 'Where does the profit go?',
                        answer: 'The profit received remains in your exchange account in its entirety.',
                    },
                    {
                        question: 'How do I acquire RCC?',
                        answer: `Use the form in your RealColibri account or on
                            the Global markets and Crypto markets pages.`,
                    },
                    {
                        question: 'What can I do with RCC?',
                        answer: `Fund the commission deposit and use them as a commission for using autotrading. <br/>
                            Sell directly to another RealColibri member who wishes to purchase RCC. <br/>
                            Trade them on exchanges once RCC is listed in the future.`,
                    },
                ],
            },
        },
    },
    es: {
        about: {
            learnMore: 'Aprende más sobre RealColibri',
            autotrading: {
                header: 'Automatización',
                welcome:
                    '¡Bienvenido al mundo del trading de alta tecnología con RealColibri!',
                description: `RealColibri es un sistema de trading automatizado avanzado que se especializa en contratos
                            de futuros de intercambios de criptomonedas y mercados globales. Nuestro sistema se caracteriza
                            por un éxito sobresaliente a largo plazo y un enfoque innovador en la inversión.`,
                unique: '¿Qué hace único al sistema RealColibri?',
                list: [
                    'Resultados excelentes: RealColibri ofrece resultados impresionantes a largo plazo. Nuestro sistema se basa en algoritmos avanzados y un análisis riguroso de datos, lo que nos permite lograr un crecimiento de inversión estable y significativo, así como minimizar el riesgo.',
                    'Enfoque automatizado: ¡Olvídate de la necesidad de monitorear constantemente el mercado! RealColibri analiza automáticamente miles de puntos de datos y toma decisiones de trading en tiempo real, liberándote del estrés y aumentando tu rendimiento de inversión.',
                    'Diversificación: La capacidad de operar en diferentes instrumentos dentro de una cuenta creando pares adicionales de clave API o usuario-contraseña y utilizándolos en múltiples cuentas RealColibri.',
                    'Uso del token RCC: RealColibri utiliza RCC como comisión por servicios en la cantidad del 50% del tamaño del depósito. El depósito de comisión se gasta en cada operación. Esta solución innovadora permite a los inversores "alimentar" el algoritmo para operar, asegurando que siempre esté funcionando y optimizando el proceso de inversión. Habiendo gastado 1 RCC en la comisión, el inversor recibe 2 veces más en su cuenta de trading. Al mismo tiempo, el inversor mantiene el beneficio en su totalidad. Y gracias al crecimiento constante del valor de RCC, la comisión de RealColibri se vuelve cada vez menor con el tiempo.',
                    'Período de prueba gratuito: ¡Prueba nuestro algoritmo de forma gratuita durante una semana! También puedes extender este período por una semana más por cada cliente que invites a nuestro sistema. Lee más sobre esta característica en la sección de FAQ.',
                ],
            },
            realColibriCoin: {
                header: 'Moneda RealColibri',
                welcome: '¡Abre nuevos horizontes con la Moneda RealColibri!',
                description: `RCC es más que una criptomoneda. Es un elemento clave de todo nuestro ecosistema, contribuyendo a su crecimiento y desarrollo constante.
                            Piensa en RCC como el combustible que impulsa nuestros servicios y los hace aún más poderosos y eficientes. El constante giro de RCC en el ecosistema RealColibri contribuye al valor del token y crea un entorno favorable para todos los participantes:`,
                list: [
                    'Comisión favorable: Cuanto mayor sea el valor de RCC, menos tokens se cobrarán por los servicios de automatización de trading de RealColibri, haciéndolos aún más asequibles y atractivos para los traders.',
                    'Intercambio de tokens: Nuestro ecosistema también permite a los usuarios vender/intercambiar RCC entre ellos sin la intervención de RealColibri. En el futuro, cuando listemos RCC en intercambios centralizados y descentralizados, el token será negociable. Esto te da aún más libertad y flexibilidad en la gestión de tus activos.',
                ],
                whatCanIDo: '¿Qué puedo hacer con RCC?',
                actions: [
                    'Financiar el depósito de la comisión y utilizarlos como comisión por el uso de la automatización del trading.',
                    'Vender directamente a otro miembro de RealColibri que desee comprar RCC.',
                    'Intercambiarlos en los intercambios una vez que RCC esté listado en el futuro.',
                ],
                joinNow:
                    '¡Únete a RealColibri hoy y descubre nuevas oportunidades y caminos hacia el éxito financiero!',
            },
            faq: {
                header: 'FAQ',
                questions: [
                    {
                        question: '¿Cómo empiezo a operar?',
                        answer: `Para empezar a operar automáticamente necesitarás: <br/>
                            Regístrate en nuestro sitio web y conecta la billetera TronLink. <br/>
                            Compra RCC y recarga el depósito de la comisión en tu cuenta RealColibri. <br/>
                            Conecta tu cuenta de trading en la página de trading usando claves API,
                            selecciona un instrumento de trading y comienza el algoritmo.`,
                    },
                    {
                        question:
                            '¿Con cuánto puedo empezar a operar y cuál es el retorno?',
                        answer: `El depósito mínimo es de 10,000 USDT. El tamaño óptimo del depósito debe ser al menos
                            3.75 veces el depósito mínimo. Si el tamaño del depósito es menor que el óptimo,
                            la ganancia mensual depende del tamaño del depósito mínimo. Si el tamaño del depósito
                            es mayor que el óptimo, se habilita el cálculo del interés compuesto, y la ganancia mensual depende
                            del tamaño actual del depósito y aumentará proporcionalmente al tamaño del depósito. Cada 5% de ganancia aumenta el
                            volumen de la posición en un 5%. Por lo tanto, cuanto mayor sea el depósito, mayor será el retorno.`,
                    },
                    {
                        question: '¿Puedo probar el trading automático gratis?',
                        answer: `Sí, estamos ofreciendo la oportunidad de utilizar el algoritmo de forma gratuita durante una semana.
                        Durante este periodo no necesitará tener RCC para ejecutar el algoritmo. Para activar el periodo de prueba gratuito,
                        envíe un correo electrónico a mail@realcolibri.com
                        con la solicitud correspondiente y la dirección de su monedero Tronlink con la que se registró en nuestro sitio web
                        y le concederemos acceso lo antes posible. Cada cliente activo que invite a nuestro sistema tiene garantizada una semana extra de tiempo gratuito.
                        Simplemente invite a un amigo, facilítele la dirección de su monedero TronLink registrado en nuestro sistema y obtendrá una semana más de prueba gratuita.
                        Cada cliente debe estar registrado en RealColibri a través de TronLink (Acceso Completo) para recibir una prueba de trading gratuita.
                        Inscription et connexion à RealColibri via TronLink`,
                    },
                    {
                        question: '¿Dónde va la ganancia?',
                        answer: 'La ganancia recibida permanece en tu cuenta de intercambio en su totalidad.',
                    },
                    {
                        question: '¿Cómo adquiero RCC?',
                        answer: `Utiliza el formulario en tu cuenta RealColibri o en
                            las páginas de Mercados Globales y Mercados de Cripto.`,
                    },
                    {
                        question: '¿Qué puedo hacer con RCC?',
                        answer: `Financiar el depósito de la comisión y utilizarlos como comisión por el uso de la automatización del trading. <br/>
                            Vender directamente a otro miembro de RealColibri que desee comprar RCC. <br/>
                            Intercambiarlos en los intercambios una vez que RCC esté listado en el futuro.`,
                    },
                ],
            },
        },
    },
    ru: {
        about: {
            learnMore: 'Узнайте больше о RealColibri',
            autotrading: {
                header: 'Автоматическая торговля',
                welcome:
                    'Добро пожаловать в мир высокотехнологичной торговли с RealColibri!',
                description: `RealColibri — это передовая автоматизированная система торговли, специализирующаяся на фьючерсных
                            контрактах криптовалютных бирж и глобальных рынков. Наша система характеризуется
                            выдающимися долгосрочными успехами и инновационным подходом к инвестированию.`,
                unique: 'Что делает систему RealColibri уникальной?',
                list: [
                    'Превосходные результаты: RealColibri обеспечивает впечатляющие результаты в долгосрочной перспективе. Наша система основана на передовых алгоритмах и тщательном анализе данных, что позволяет нам достигать стабильного и значительного роста инвестиций, а также минимизировать риски.',
                    'Автоматический подход: Забудьте о необходимости постоянно следить за рынком! RealColibri автоматически анализирует тысячи точек данных и принимает торговые решения в реальном времени, освобождая вас от стресса и повышая вашу инвестиционную эффективность.',
                    'Диверсификация: Возможность торговать разными инструментами в одной учетной записи, создавая дополнительные пары ключей API или логин-пароль и используя их на нескольких учетных записях RealColibri.',
                    'Использование токена RCC: RealColibri использует RCC в качестве комиссии за услуги в размере 50% от суммы депозита. Комиссионный депозит тратится на каждую сделку. Это инновационное решение позволяет инвесторам "питать" алгоритм для торговли, гарантируя его постоянную работу и оптимизацию инвестиционного процесса. Потратив 1 RCC на комиссию, инвестор получает в 2 раза больше на своем торговом счете! При этом инвестор сохраняет всю прибыль. А благодаря постоянному росту стоимости RCC комиссия RealColibri со временем становится все меньше и меньше.',
                    'Бесплатный пробный период: Попробуйте наш алгоритм бесплатно в течение одной недели! Вы также можете продлить этот период на еще одну неделю за каждого клиента, которого вы пригласите в нашу систему. Подробнее об этой функции читайте в разделе FAQ.',
                ],
            },
            realColibriCoin: {
                header: 'Монета RealColibri',
                welcome: 'Откройте новые горизонты с монетой RealColibri!',
                description: `RCC — это не просто криптовалюта. Это ключевой элемент всей нашей экосистемы, способствующий ее постоянному росту и развитию.
                            Думайте о RCC как о топливе, которое питает наши услуги и делает их еще более мощными и эффективными. Постоянный оборот RCC в экосистеме RealColibri способствует росту стоимости токена и создает благоприятную среду для всех участников:`,
                list: [
                    'Выгодная комиссия: Чем выше стоимость RCC, тем меньше токенов будет взиматься за услуги автоматизации торговли RealColibri, делая их еще более доступными и привлекательными для трейдеров.',
                    'Обмен токенов: Наша экосистема также позволяет пользователям продавать/обменивать RCC друг с другом без участия RealColibri. В будущем, когда мы листим RCC на централизованных и децентрализованных биржах, токен будет торгуемым. Это дает вам еще больше свободы и гибкости в управлении вашими активами.',
                ],
                whatCanIDo: 'Что я могу сделать с RCC?',
                actions: [
                    'Пополнить комиссионный депозит и использовать их в качестве комиссии за использование автоматической торговли.',
                    'Продать напрямую другому участнику RealColibri, который желает приобрести RCC.',
                    'Торговать ими на биржах, когда RCC будет листиться в будущем.',
                ],
                joinNow:
                    'Присоединяйтесь к RealColibri сегодня и откройте новые возможности и пути к финансовому успеху!',
            },
            faq: {
                header: 'FAQ',
                questions: [
                    {
                        question: 'Как начать торговать?',
                        answer: `Чтобы начать автоматическую торговлю, вам нужно: <br/>
                            Зарегистрируйтесь на нашем сайте и подключите кошелек TronLink. <br/>
                            Приобретите RCC и пополните комиссионный депозит в своем аккаунте RealColibri. <br/>
                            Подключите свою торговую учетную запись на странице торговли, используя API-ключи,
                            выберите торговый инструмент и запустите алгоритм.`,
                    },
                    {
                        question:
                            'С какой суммы я могу начать торговать и какой доход?',
                        answer: `Минимальный депозит составляет 10,000 USDT. Оптимальный размер депозита должен быть не менее
                            3.75 раз больше минимального депозита. Если размер депозита меньше оптимального,
                            ежемесячная прибыль зависит от минимального размера депозита. Если размер депозита
                            больше оптимального, включается расчет сложного процента, и ежемесячная прибыль зависит
                            от текущего размера депозита и будет увеличиваться пропорционально размеру депозита. Каждые 5% прибыли увеличивают
                            объем позиции на 5%. Поэтому чем больше депозит, тем выше доход.`,
                    },
                    {
                        question:
                            'Могу ли я попробовать автоматическую торговлю бесплатно?',
                        answer: `Да, мы предлагаем возможность использовать алгоритм бесплатно в течение одной недели.
                        В течение этого периода вам не нужно будет иметь RCC для запуска алгоритма.
                        Чтобы активировать бесплатный пробный период,
                        отправьте электронное письмо на адрес mail@realcolibri.com с соответствующим запросом и адресом вашего кошелька Tronlink,
                        с которым вы зарегистрировались на нашем сайте, и мы предоставим вам доступ как можно скорее.
                        Каждый активный клиент, которого вы пригласите в нашу систему, гарантированно прибавляет вам одну дополнительную неделю бесплатного периода.
                        Просто пригласите друга, предоставьте адрес его кошелька TronLink, зарегистрированного в нашей системе,
                        и вы получаете еще одну неделю бесплатного пробного периода.
                        Для этого каждый клиент должен быть зарегистрированным в системе RealColibri через TronLink (Полный доступ),
                        чтобы получить бесплатный пробный период торговли.`,
                    },
                    {
                        question: 'Куда идет прибыль?',
                        answer: 'Полученная прибыль остается на вашем биржевом счете в полном объеме.',
                    },
                    {
                        question: 'Как приобрести RCC?',
                        answer: `Используйте форму в своем аккаунте RealColibri или на
                            страницах Глобальные рынки и Криптовалютные рынки.`,
                    },
                    {
                        question: 'Что я могу сделать с RCC?',
                        answer: `Пополните комиссионный депозит и используйте их в качестве комиссии за использование автоматической торговли. <br/>
                            Продайте напрямую другому участнику RealColibri, который желает приобрести RCC. <br/>
                            Торгуйте ими на биржах, когда RCC будет листиться в будущем.`,
                    },
                ],
            },
        },
    },
    hy: {
        about: {
            learnMore: 'Իմացեք ավելին RealColibri-ի մասին',
            autotrading: {
                header: 'Ավտոմատ առևտուր',
                welcome:
                    'Բարի գալուստ բարձր տեխնոլոգիական առևտրի աշխարհ՝ RealColibri-ի հետ!',
                description: `RealColibri-ն առաջատար ավտոմատացված առևտրի համակարգ է, որը մասնագիտացված է կրիպտոարժույթների բորսաների
                          և համաշխարհային շուկաների ֆյուչերսային պայմանագրերի վրա։ Մեր համակարգը բնութագրվում է երկարաժամկետ հաջողություններով
                          և նորարարական ներդրումային մոտեցմամբ։`,
                unique: 'Ինչ է դարձնում RealColibri համակարգը եզակի?',
                list: [
                    'Բարձր արդյունքներ: RealColibri-ն ապահովում է տպավորիչ արդյունքներ երկարաժամկետ հեռանկարում։ Մեր համակարգը հիմնված է առաջադեմ ալգորիթմների վրա և մանրակրկիտ տվյալների վերլուծությամբ, ինչը թույլ է տալիս հասնել կայուն և նշանակալի ներդրումային աճին, ինչպես նաև նվազեցնել ռիսկերը։',
                    'Ավտոմատացված մոտեցում: Մոռացեք շուկան մշտապես հետևելու անհրաժեշտության մասին: RealColibri-ն ավտոմատ կերպով վերլուծում է հազարավոր տվյալների կետեր և իրական ժամանակում ընդունում առևտրային որոշումներ, ազատելով ձեզ սթրեսից և բարձրացնելով ձեր ներդրումային արդյունավետությունը:',
                    'Դիվերսիֆիկացիա: Մեկ հաշվում հնարավոր է տարբեր գործիքներով առևտուր իրականացնել, ստեղծելով հավելյալ API բանալիների զույգեր կամ լոգին-գաղտնաբառ և օգտագործելով դրանք մի քանի RealColibri հաշիվներում:',
                    'RCC թոքենի օգտագործումը: RealColibri-ն օգտագործում է RCC որպես ծառայությունների միջնորդավճար 50%-ով հանած գումարի գումարից: Միջնորդավճարային դեպոզիտը ծախսվում է յուրաքանչյուր գործարքի վրա: Այս նորարարական լուծումը թույլ է տալիս ներդրողներին "կերակրել" ալգորիթմը առևտրի համար՝ ապահովելով նրա մշտական աշխատանքը և ներդրումային գործընթացի օպտիմալացումը։ Մեկ RCC ծախսելով միջնորդավճարով, ներդրողը ստանում է կրկնակի ավելին իր առևտրային հաշվին! Բացի այդ, RCC արժեքի շարունակական աճի շնորհիվ RealColibri-ի միջնորդավճարը ժամանակի ընթացքում դառնում է ավելի փոքր և ավելի փոքր:',
                    'Անվճար փորձաշրջան: Փորձեք մեր ալգորիթմը անվճար մեկ շաբաթվա ընթացքում: Դուք կարող եք նաև երկարաձգել այս շրջանը յուրաքանչյուր հաճախորդի համար, որին դուք հրավիրեք մեր համակարգում։ Այս գործառույթի մասին ավելի մանրամասն կարդացեք FAQ բաժնում:',
                ],
            },
            realColibriCoin: {
                header: 'RealColibri մետաղադրամ',
                welcome: 'Բացահայտեք նոր հորիզոններ RealColibri մետաղադրամով!',
                description:
                    'RCC-ն պարզապես կրիպտոարժույթ չէ։ Դա մեր էկոհամակարգի հիմնական տարրն է, որը նպաստում է նրա շարունակական աճին և զարգացմանը։ Կարծեք RCC-ի մասին որպես վառելիք, որը սնուցում է մեր ծառայությունները և դրանք դարձնում ավելի հզոր և արդյունավետ: RCC-ի շարունակական շրջանառությունը RealColibri էկոհամակարգում նպաստում է թոքենի արժեքի աճին և ստեղծում բարենպաստ միջավայր բոլոր մասնակիցների համար։',
                list: [
                    'Հարմարավետ միջնորդավճար: Որքան բարձր է RCC-ի արժեքը, այնքան պակաս թոքեններ են պահանջվում RealColibri-ի ավտոմատացված առևտրային ծառայությունների համար, դարձնելով դրանք ավելի մատչելի և գրավիչ առևտրականների համար։',
                    'Թոքենների փոխանակում: Մեր էկոհամակարգը նաև թույլ է տալիս օգտատերերին վաճառել/փոխանակել RCC առանց RealColibri-ի միջամտության։ Ապագայում, երբ մենք կցուցենք RCC-ն կենտրոնացված և դեչենտրալիզացված բորսաներում, թոքենը կլինի առևտրի առարկա։ Սա ձեզ ավելի շատ ազատություն և ճկունություն է տալիս ձեր ակտիվների կառավարմանը։',
                ],
                whatCanIDo: 'Ինչ կարող եմ անել RCC-ով?',
                actions: [
                    'Լրացնել միջնորդավճարային դեպոզիտը և օգտագործել այն որպես ավտոմատացված առևտրի ծառայության համար միջնորդավճար։',
                    'Վաճառել անմիջապես այլ RealColibri-ի մասնակցի, ով ցանկանում է ձեռք բերել RCC։',
                    'Առևտրել դրանցով բորսաներում, երբ RCC-ն ցուցակագրվի ապագայում։',
                ],
                joinNow:
                    'Միացեք RealColibri-ին այսօր և բացահայտեք նոր հնարավորություններ ու ուղիներ դեպի ֆինանսական հաջողություն!',
            },
            faq: {
                header: 'Հաճախակի տրվող հարցեր',
                questions: [
                    {
                        question: 'Ինչպես սկսել առևտուրը?',
                        answer: `Ավտոմատ առևտուր սկսելու համար ձեզ անհրաժեշտ է: <br/>
                         Գրանցվեք մեր կայքում և կապեք TronLink դրամապանակը։ <br/>
                         Գնեք RCC և լրացրեք միջնորդավճարային դեպոզիտը ձեր RealColibri հաշվում։ <br/>
                         Կապեք ձեր առևտրային հաշիվը առևտրի էջում՝ օգտագործելով API բանալիներ, 
                         ընտրեք առևտրային գործիքը և մեկնարկեք ալգորիթմը։`,
                    },
                    {
                        question:
                            'Որքա՞ն գումարով կարող եմ սկսել առևտուրը և ինչպիսի՞ եկամուտ կունենամ:',
                        answer: `Նվազագույն դեպոզիտը 10,000 USDT է։ Օպտիմալ դեպոզիտի չափը պետք է լինի առնվազն
                         3.75 անգամ ավելի մեծ նվազագույն դեպոզիտից։ Եթե դեպոզիտի չափը ավելի փոքր է օպտիմալից,
                         ամսական եկամուտը կախված կլինի նվազագույն դեպոզիտից։ Եթե դեպոզիտի չափը
                         մեծ է օպտիմալից, բարդ տոկոսների հաշվարկը կիրարկվում է, և ամսական եկամուտը կախված կլինի
                         դեպոզիտի ընթացիկ չափից և կավելանա դեպոզիտի չափին համամասնորեն։ Ամեն 5% շահույթը ավելացնում է
                         պոզիցիայի ծավալը 5%-ով։ Հետևաբար, որքան մեծ է դեպոզիտը, այնքան բարձր է եկամուտը։`,
                    },
                    {
                        question: 'Կարո՞ղ եմ փորձել ավտոմատ առևտուրը անվճար:',
                        answer: `Այո, մենք առաջարկում ենք մեկ շաբաթ անվճար ալգորիթմից օգտվելու հնարավորություն։
                Այս ժամանակահատվածում ալգորիթմը գործարկելու համար ձեզ հարկավոր չէ ունենալ RCC:
                Ձեր անվճար փորձաշրջանն ակտիվացնելու համար նամակ ուղարկեք mail@realcolibri.com հասցեին՝
                ձեր խնդրանքով և ձեր Tronlink դրամապանակի հասցեն, որով գրանցվել եք մեր կայքում, և մենք ձեզ հնարավորինս շուտ հասանելիություն կտրամադրենք:
                Յուրաքանչյուր ակտիվ հաճախորդ, որը դուք հրավիրում եք մեր համակարգ, երաշխավորված է ձեզ լրացուցիչ մեկ շաբաթ անվճար ժամկետով: Պարզապես հրավիրեք ձեր ընկերոջը,
                տրամադրեք նրանց TronLink դրամապանակի հասցեն, որը գրանցված է մեր համակարգում, և դուք կստանաք ևս մեկ շաբաթ անվճար փորձաշրջան:
                Դա անելու համար յուրաքանչյուր հաճախորդ պետք է գրանցված լինի RealColibri համակարգում TronLink-ի միջոցով (Full Access)՝ անվճար առևտրային փորձարկում ստանալու համար:`,
                    },
                    {
                        question: 'Ո՞ւր է գնում շահույթը:',
                        answer: 'Ստացված շահույթը մնում է ձեր բորսային հաշվին ամբողջությամբ։',
                    },
                    {
                        question: 'Ինչպես ձեռք բերել RCC?',
                        answer: `Օգտագործեք ձեր RealColibri հաշվի ձևը կամ
                         Գլոբալ շուկաներ և Կրիպտոարժույթների շուկաներ էջերը։`,
                    },
                    {
                        question: 'Ինչ կարող եմ անել RCC-ով?',
                        answer: `Լրացրեք միջնորդավճարային դեպոզիտը և օգտագործեք այն որպես ավտոմատացված առևտրի համար միջնորդավճար։ <br/>
                         Վաճառեք անմիջապես այլ RealColibri մասնակցի, ով ցանկանում է ձեռք բերել RCC։ <br/>
                         Առևտրեք դրանցով բորսաներում, երբ RCC-ն ցուցակագրվի ապագայում։`,
                    },
                ],
            },
        },
    },
    de: {
        about: {
            learnMore: 'Erfahren Sie mehr über RealColibri',
            autotrading: {
                header: 'Autotrading',
                welcome:
                    'Willkommen in der Welt des Hightech-Handels mit RealColibri!',
                description: `RealColibri ist ein fortschrittliches automatisiertes Handelssystem, das sich auf
                            Terminkontrakte von Kryptobörsen und globalen Märkten spezialisiert hat.
                            Unser System zeichnet sich durch langfristige Erfolge und einen innovativen
                            Ansatz beim Investieren aus.`,
                unique: 'Was macht das RealColibri-System einzigartig:',
                list: [
                    'Ausgezeichnete Ergebnisse: RealColibri liefert beeindruckende Ergebnisse auf lange Sicht. Unser System basiert auf fortschrittlichen Algorithmen und einer gründlichen Datenanalyse, die es uns ermöglichen, ein stabiles und signifikantes Investmentwachstum zu erzielen und das Risiko zu minimieren.',
                    'Automatisierter Ansatz: Vergessen Sie die Notwendigkeit, den Markt ständig zu überwachen! RealColibri analysiert automatisch Tausende von Datenpunkten und trifft Handelsentscheidungen in Echtzeit, wodurch Sie vom Stress befreit werden und Ihre Investmentleistung gesteigert wird.',
                    'Diversifikation: Die Möglichkeit, verschiedene Instrumente innerhalb eines Kontos zu handeln, indem Sie zusätzliche API-Schlüssel oder Login-Passwort-Paare erstellen und diese auf mehreren RealColibri-Konten verwenden.',
                    'Verwendung des RCC-Tokens: RealColibri verwendet RCC als Provision für Dienstleistungen in Höhe von 50 % der Depotgröße. Die Provisions-Einlage wird bei jedem Handel verbraucht. Diese innovative Lösung ermöglicht es Investoren, den Algorithmus für den Handel zu "tanken", wodurch er immer läuft und den Investmentprozess optimiert. Nachdem ein RCC als Provision verbraucht wurde, erhält der Investor das Doppelte auf seinem Handelskonto! Gleichzeitig behält der Investor den Gewinn in voller Höhe. Und dank des ständigen Wertzuwachses von RCC wird die Provision von RealColibri im Laufe der Zeit immer kleiner.',
                    'Kostenlose Testphase: Testen Sie unseren Algorithmus eine Woche lang kostenlos! Sie können diesen Zeitraum auch um eine weitere Woche verlängern, für jeden Kunden, den Sie in unser System einladen. Weitere Informationen zu dieser Funktion finden Sie im FAQ-Bereich.',
                ],
            },
            realColibriCoin: {
                header: 'RealColibri Coin',
                welcome: 'Entdecken Sie neue Horizonte mit RealColibri Coin!',
                description: `RCC ist mehr als nur eine Kryptowährung. Es ist ein Schlüsselelement unseres gesamten
                            Ökosystems, das zu seinem ständigen Wachstum und seiner Entwicklung beiträgt.
                            Betrachten Sie RCC als den Treibstoff, der unsere Dienste antreibt und sie noch leistungsfähiger und effizienter macht. Der ständige Umsatz von RCC im RealColibri-Ökosystem trägt zum Wert des Tokens bei und schafft ein günstiges Umfeld für alle Teilnehmer:`,
                list: [
                    `Günstige Provision: Je höher der Wert von RCC, desto weniger Tokens werden für die
                    Handelsautomatisierungsdienste von RealColibri berechnet, wodurch sie noch erschwinglicher und attraktiver für Händler werden.`,
                    `Token-Austausch: Unser Ökosystem ermöglicht es Benutzern auch, RCC untereinander ohne
                    Eingriff von RealColibri zu verkaufen/zu tauschen. In Zukunft, wenn wir RCC an zentralisierten
                    und dezentralisierten Börsen listen, wird der Token handelbar sein. Dies gibt Ihnen noch mehr Freiheit und Flexibilität bei der Verwaltung Ihrer Vermögenswerte.`,
                ],
                whatCanIDo: 'Was kann ich mit RCC tun?',
                actions: [
                    'Finanzieren Sie die Provisions-Einlage und verwenden Sie diese als Provision für die Nutzung des automatisierten Handels.',
                    'Verkaufen Sie direkt an ein anderes RealColibri-Mitglied, das RCC kaufen möchte.',
                    'Handeln Sie diese an Börsen, sobald RCC in der Zukunft gelistet ist.',
                ],
                joinNow:
                    'Schließen Sie sich RealColibri heute an und entdecken Sie neue Chancen und Wege zum finanziellen Erfolg!',
            },
            faq: {
                header: 'FAQ',
                questions: [
                    {
                        question: 'Wie starte ich den Handel?',
                        answer: `Um den automatisierten Handel zu starten, benötigen Sie: <br/>
                            Registrieren Sie sich auf unserer Website und verbinden Sie die TronLink-Brieftasche. <br/>
                            Kaufen Sie RCC und füllen Sie die Provisions-Einlage in Ihrem RealColibri-Konto auf. <br/>
                            Verbinden Sie Ihr Handelskonto auf der Handelsseite mit API-Schlüsseln,
                            wählen Sie ein Handelsinstrument aus und starten Sie den Algorithmus.`,
                    },
                    {
                        question:
                            'Mit wie viel kann ich den Handel starten und wie hoch ist der Ertrag?',
                        answer: `Die Mindesteinlage beträgt 10.000 USDT. Die optimale Einlagengröße sollte mindestens
                            das 3,75-fache der Mindesteinlage betragen. Wenn die Einlagengröße kleiner als die optimale
                            Größe ist, hängt der monatliche Gewinn von der Mindesteinlage ab. Wenn die Einlagengröße
                            größer ist als die optimale Größe, wird die Berechnung des Zinseszinses aktiviert, und der monatliche Gewinn hängt
                            von der aktuellen Einlagengröße ab und wird proportional zur Einlagengröße zunehmen. Jeder 5% Gewinn erhöht das
                            Positionsvolumen um 5%. Daher gilt: Je größer die Einlage, desto höher der Ertrag.`,
                    },
                    {
                        question:
                            'Kann ich den automatisierten Handel kostenlos ausprobieren?',
                        answer: `Ja, wir bieten Ihnen die Möglichkeit, den Algorithmus eine Woche lang kostenlos zu nutzen.
                        Während dieses Zeitraums benötigen Sie keine RCC, um den Algorithmus zu nutzen.
                        Um die kostenlose Testphase zu aktivieren, senden Sie eine E-Mail an mail@realcolibri.com mit der entsprechenden Anfrage und Ihrer Tronlink-Wallet-Adresse,
                        mit der Sie sich auf unserer Website registriert haben, und wir werden Ihnen so schnell wie möglich Zugang gewähren.
                        Jeder aktive Kunde, den Sie zu unserem System einladen, erhält garantiert eine zusätzliche Woche Gratiszeit.
                        Laden Sie einfach einen Freund ein, geben Sie die Adresse seiner in unserem System registrierten TronLink-Brieftasche an,
                        und Sie erhalten eine weitere kostenlose Testwoche. Jeder Kunde muss bei RealColibri über TronLink (Full Access) registriert sein,
                        um eine kostenlose Probezeit zu erhalten.`,
                    },
                    {
                        question: 'Wohin geht der Gewinn?',
                        answer: 'Der erzielte Gewinn verbleibt vollständig auf Ihrem Handelskonto.',
                    },
                    {
                        question: 'Wie erwerbe ich RCC?',
                        answer: `Verwenden Sie das Formular in Ihrem RealColibri-Konto oder auf den
                            Seiten Globale Märkte und Kryptomärkte.`,
                    },
                    {
                        question: 'Was kann ich mit RCC tun?',
                        answer: `Finanzieren Sie die Provisions-Einlage und verwenden Sie sie als Provision für die Nutzung des automatisierten Handels. <br/>
                            Verkaufen Sie direkt an ein anderes RealColibri-Mitglied, das RCC kaufen möchte. <br/>
                            Handeln Sie diese an Börsen, sobald RCC in der Zukunft gelistet ist.`,
                    },
                ],
            },
        },
    },
    fr: {
        about: {
            learnMore: 'En savoir plus sur RealColibri',
            autotrading: {
                header: 'Trading automatique',
                welcome:
                    'Bienvenue dans le monde du trading high-tech avec RealColibri!',
                description: `RealColibri est un système de trading automatisé avancé spécialisé dans les contrats à terme
                            des échanges cryptographiques et des marchés mondiaux. Notre système se caractérise par
                            un succès à long terme exceptionnel et une approche innovante de l'investissement.`,
                unique: 'Ce qui rend le système RealColibri unique:',
                list: [
                    "Excellents résultats: RealColibri fournit des résultats impressionnants à long terme. Notre système est basé sur des algorithmes avancés et une analyse de données rigoureuse, ce qui nous permet d'obtenir une croissance stable et significative des investissements, tout en minimisant les risques.",
                    "Approche automatisée: Oubliez la nécessité de surveiller constamment le marché! RealColibri analyse automatiquement des milliers de points de données et prend des décisions de trading en temps réel, vous libérant du stress et augmentant vos performances d'investissement.",
                    "Diversification: La possibilité de trader sur différents instruments au sein d'un même compte en créant des paires de clés API ou login-mot de passe supplémentaires et en les utilisant sur plusieurs comptes RealColibri.",
                    "Utilisation du token RCC: RealColibri utilise RCC comme commission pour les services à hauteur de 50 % de la taille du dépôt. Le dépôt de commission est dépensé à chaque transaction. Cette solution innovante permet aux investisseurs de \"alimenter\" l'algorithme pour trader, en s'assurant qu'il fonctionne toujours et en optimisant le processus d'investissement. Après avoir dépensé 1 RCC en commission, l'investisseur reçoit deux fois plus sur son compte de trading! En même temps, l'investisseur conserve l'intégralité du bénéfice. Et grâce à la croissance constante de la valeur de RCC, la commission de RealColibri devient de plus en plus petite avec le temps.\"",
                    "Période d'essai gratuite: Essayez notre algorithme gratuitement pendant une semaine! Vous pouvez également prolonger cette période d'une semaine supplémentaire pour chaque client que vous invitez à notre système. Lisez-en plus sur cette fonctionnalité dans la section FAQ.",
                ],
            },
            realColibriCoin: {
                header: 'RealColibri Coin',
                welcome:
                    'Découvrez de nouveaux horizons avec le RealColibri Coin!',
                description: `RCC est bien plus qu'une simple cryptomonnaie. C'est un élément clé de tout notre écosystème,
                            contribuant à sa croissance et à son développement constants. Considérez RCC comme le carburant qui alimente nos services et les rend encore plus puissants et efficaces. Le chiffre d'affaires constant de RCC dans l'écosystème RealColibri contribue à la valeur du token et crée un environnement favorable pour tous les participants:`,
                list: [
                    `Commission favorable: Plus la valeur de RCC est élevée, moins de tokens seront
                    facturés pour les services d'automatisation du trading de RealColibri, les rendant ainsi encore plus abordables et attrayants pour les traders.`,
                    `Échange de tokens: Notre écosystème permet également aux utilisateurs de vendre/échanger RCC entre eux sans
                    l'intervention de RealColibri. À l'avenir, lorsque nous listerons RCC sur des bourses centralisées et décentralisées, le token sera négociable. Cela vous donne encore plus de liberté et de flexibilité dans la gestion de vos actifs.`,
                ],
                whatCanIDo: 'Que puis-je faire avec RCC?',
                actions: [
                    "Alimentez le dépôt de commission et utilisez-les comme commission pour l'utilisation du trading automatisé.",
                    'Vendez-les directement à un autre membre de RealColibri qui souhaite acheter RCC.',
                    "Tradez-les sur des bourses une fois que RCC sera listé à l'avenir.",
                ],
                joinNow:
                    "Rejoignez RealColibri dès aujourd'hui et découvrez de nouvelles opportunités et des chemins vers le succès financier!",
            },
            faq: {
                header: 'FAQ',
                questions: [
                    {
                        question: 'Comment commencer à trader?',
                        answer: `Pour commencer le trading automatisé, vous aurez besoin de: <br/>
                            Vous inscrire sur notre site web et connecter le portefeuille TronLink. <br/>
                            Acheter du RCC et recharger le dépôt de commission dans votre compte RealColibri. <br/>
                            Connectez votre compte de trading sur la page de trading en utilisant les clés API,
                            sélectionnez un instrument de trading et lancez l'algorithme.`,
                    },
                    {
                        question:
                            'Avec combien puis-je commencer à trader et quel est le rendement?',
                        answer: `Le dépôt minimum est de 10 000 USDT. La taille optimale du dépôt devrait être au moins
                            3,75 fois la taille du dépôt minimum. Si la taille du dépôt est inférieure à celle optimale,
                            le profit mensuel dépend de la taille du dépôt minimum. Si la taille du dépôt
                            est supérieure à celle optimale, le calcul des intérêts composés est activé, et le profit mensuel dépendra
                            de la taille actuelle du dépôt et augmentera proportionnellement à la taille du dépôt. Chaque 5 % de profit augmente
                            le volume de la position de 5 %. Par conséquent, plus le dépôt est grand, plus le rendement est élevé.`,
                    },
                    {
                        question:
                            'Puis-je essayer le trading automatique gratuitement?',
                        answer: `Oui, nous offrons la possibilité d'utiliser l'algorithme gratuitement pendant une semaine.
                        Pendant cette période, vous n'aurez pas besoin d'avoir RCC pour utiliser l'algorithme. Pour activer la période d'essai gratuite,
                        envoyez un e-mail à mail@realcolibri.com
                        avec la demande appropriée et votre adresse de portefeuille Tronlink
                        avec laquelle vous vous êtes enregistré sur notre site Web et nous vous accorderons l'accès dès que possible.
                        Chaque client actif que vous invitez à notre système est assuré d'obtenir une semaine supplémentaire de temps libre.
                        Il suffit d'inviter un ami, de lui fournir l'adresse de son portefeuille TronLink enregistré dans notre système,
                        et vous obtiendrez une semaine supplémentaire d'essai gratuit.
                        Chaque client doit être enregistré auprès de RealColibri via TronLink (Full Access) pour recevoir un essai de trading gratuit.
                        Inscription et connexion à RealColibri via TronLink`,
                    },
                    {
                        question: 'Où va le profit?',
                        answer: "Le profit obtenu reste entièrement sur votre compte d'échange.",
                    },
                    {
                        question: 'Comment acquérir du RCC?',
                        answer: `Utilisez le formulaire dans votre compte RealColibri ou sur
                            les pages Marchés mondiaux et Marchés des cryptomonnaies.`,
                    },
                    {
                        question: 'Que puis-je faire avec RCC?',
                        answer: `Alimentez le dépôt de commission et utilisez-le comme commission pour l'utilisation du trading automatisé. <br/>
                            Vendez-les directement à un autre membre de RealColibri qui souhaite acheter du RCC. <br/>
                            Tradez-les sur des bourses une fois que RCC sera listé à l'avenir.`,
                    },
                ],
            },
        },
    },
    it: {
        about: {
            learnMore: 'Scopri di più su RealColibri',
            autotrading: {
                header: 'Trading automatico',
                welcome:
                    'Benvenuto nel mondo del trading high-tech con RealColibri!',
                description: `RealColibri è un sistema di trading automatizzato avanzato, specializzato in futures
                          sugli scambi di criptovalute e nei mercati globali. Il nostro sistema si distingue per
                          un successo a lungo termine eccezionale e un approccio innovativo agli investimenti.`,
                unique: 'Cosa rende unico il sistema RealColibri:',
                list: [
                    'Ottimi risultati: RealColibri fornisce risultati impressionanti a lungo termine. Il nostro sistema è basato su algoritmi avanzati e un’analisi dati rigorosa, consentendo una crescita stabile e significativa degli investimenti, minimizzando al contempo i rischi.',
                    'Approccio automatizzato: Dimentica la necessità di monitorare costantemente il mercato! RealColibri analizza automaticamente migliaia di punti dati e prende decisioni di trading in tempo reale, liberandoti dallo stress e aumentando le tue prestazioni di investimento.',
                    'Diversificazione: Possibilità di fare trading su diversi strumenti con un solo account, creando coppie di chiavi API o login-password aggiuntive e utilizzandole su più account RealColibri.',
                    'Utilizzo del token RCC: RealColibri utilizza RCC come commissione per i servizi fino al 50% del deposito. Il deposito per le commissioni viene scalato a ogni transazione. Questa soluzione innovativa permette agli investitori di "alimentare" l\'algoritmo di trading, garantendo il funzionamento costante e ottimizzando il processo di investimento. Dopo aver speso 1 RCC in commissioni, l\'investitore riceve il doppio sul suo conto di trading! Allo stesso tempo, l’investitore mantiene il 100% del profitto. Grazie alla crescita costante del valore di RCC, la commissione di RealColibri diventa sempre più bassa nel tempo.',
                    "Periodo di prova gratuito: Prova il nostro algoritmo gratuitamente per una settimana! Puoi anche estendere questa prova di un'altra settimana per ogni cliente che inviti al nostro sistema. Scopri di più su questa funzione nella sezione FAQ.",
                ],
            },
            realColibriCoin: {
                header: 'RealColibri Coin',
                welcome: 'Scopri nuovi orizzonti con RealColibri Coin!',
                description: `RCC è molto più di una semplice criptovaluta. È un elemento chiave dell'intero ecosistema,
                          contribuendo alla sua crescita e al suo sviluppo costanti. Considera RCC come il carburante che alimenta i nostri servizi, rendendoli ancora più potenti ed efficienti. Il flusso continuo di RCC nell’ecosistema RealColibri sostiene il valore del token e crea un ambiente favorevole per tutti i partecipanti:`,
                list: [
                    'Commissioni vantaggiose: Più alto è il valore di RCC, meno token saranno richiesti per i servizi di automazione di trading di RealColibri, rendendoli ancora più accessibili e attraenti per i trader.',
                    `Scambio di token: Il nostro ecosistema permette agli utenti di vendere/scambiare RCC tra di loro senza
                l'intervento di RealColibri. In futuro, quando RCC sarà quotato su borse centralizzate e decentralizzate, il token sarà negoziabile, dando ancora più libertà e flessibilità nella gestione dei tuoi asset.`,
                ],
                whatCanIDo: 'Cosa posso fare con RCC?',
                actions: [
                    'Ricarica il deposito per le commissioni e utilizzale come commissioni per l’utilizzo del trading automatizzato.',
                    'Vendili direttamente a un altro membro di RealColibri che desidera acquistare RCC.',
                    'Scambiali su borse quando RCC sarà quotato in futuro.',
                ],
                joinNow:
                    'Unisciti a RealColibri oggi stesso e scopri nuove opportunità e percorsi verso il successo finanziario!',
            },
            faq: {
                header: 'FAQ',
                questions: [
                    {
                        question: 'Come inizio a fare trading?',
                        answer: `Per iniziare a fare trading automaticamente avrai bisogno di: <br/>
                        Registrati sul nostro sito web e collega il wallet TronLink. <br/>
                        Acquista RCC e ricarica il deposito della commissione nel tuo account RealColibri. <br/>
                        Collega il tuo account di trading nella pagina di trading utilizzando le chiavi API,
                        seleziona uno strumento di trading e avvia l'algoritmo.`,
                    },
                    {
                        question:
                            'Con quanto posso iniziare a fare trading e qual è il ritorno?',
                        answer: `Il deposito minimo è di 10.000 USDT. La dimensione ottimale del deposito deve essere almeno
                        3,75 volte il deposito minimo. Se la dimensione del deposito è inferiore a quella ottimale,
                        il guadagno mensile dipende dalla dimensione del deposito minimo. Se la dimensione del deposito
                        è superiore a quella ottimale, si abilita il calcolo dell'interesse composto, e il guadagno mensile dipende
                        dalla dimensione attuale del deposito e aumenterà proporzionalmente alla dimensione del deposito. Ogni 5% di guadagno aumenta il
                        volume della posizione del 5%. Pertanto, maggiore è il deposito, maggiore sarà il ritorno.`,
                    },
                    {
                        question:
                            'Posso provare il trading automatico gratuitamente?',
                        answer: `Sì, offriamo l'opportunità di utilizzare l'algoritmo gratuitamente per una settimana.
                    Durante questo periodo non sarà necessario avere RCC per eseguire l'algoritmo. Per attivare il periodo di prova gratuito,
                    invia un'email a mail@realcolibri.com
                    con la richiesta corrispondente e l'indirizzo del tuo wallet Tronlink con cui ti sei registrato sul nostro sito web
                    e ti concederemo l'accesso il prima possibile. Ogni cliente attivo che invita nel nostro sistema ha diritto a una settimana extra di tempo gratuito.
                    Basta invitare un amico, fornirgli l'indirizzo del tuo wallet TronLink registrato nel nostro sistema e otterrai una settimana in più di prova gratuita.
                    Ogni cliente deve essere registrato su RealColibri tramite TronLink (Accesso Completo) per ricevere una prova di trading gratuita.
                    Registrazione e accesso a RealColibri tramite TronLink`,
                    },
                    {
                        question: 'Dove va il guadagno?',
                        answer: 'Il guadagno ricevuto rimane interamente nel tuo account di scambio.',
                    },
                    {
                        question: 'Come acquisisco RCC?',
                        answer: `Utilizza il modulo nel tuo account RealColibri o nelle
                        pagine Mercati Globali e Mercati di Cripto.`,
                    },
                    {
                        question: 'Cosa posso fare con RCC?',
                        answer: `Finanziare il deposito della commissione e utilizzarli come commissione per l'uso dell'automazione del trading. <br/>
                        Venderli direttamente a un altro membro di RealColibri che desidera acquistare RCC. <br/>
                        Scambiarli negli exchange una volta che RCC sarà quotato in futuro.`,
                    },
                ],
            },
        },
    },
}
